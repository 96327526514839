import React from 'react';
import Badge from '../components/Badge';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Section from '../components/Section';
import colBkgCoop from '../images/img-home-coop-careers.jpg';
import colBkgVFT from '../images/img-home-vft.jpg';

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <Hero />
    <Section className="home__banner">
      <Container className="banner">
        <Row>
          <Column large={8} className="banner__info">
            <Image
              filename="img-home-farmer-icon.svg"
              className="icon-tractor"
            />
            <div className="banner__copy">
              <p>
                Explore our new Interactive Gamified Learning Experience
                <b>
                  <i> HARVEST: From Seed to Success</i>
                </b>
              </p>
            </div>
          </Column>
          <Column large={4} className="banner__cta">
            <Button to="gamified-learning">
              PLAY NOW
              <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    {/**** Harvest Section ****/}
    <Section className="home-harvest-experience">
      <Container>
        <Row>
          <Column size={6}>
            <Badge text="NEW!" />
            <p className="home-harvest-experience__title">
              <i>HARVEST: From Seed to Success</i>
            </p>
            <p>
              Students will step into the shoes of a co-op farmer-owner in a new
              immersive experience from Cooperative Minds! In the HARVEST game,
              students will take on the role of a farmer to learn what it takes
              to grow seeds into a successful crop. Students will consult ag
              professionals as they analyze a soil sample, choose their crop and
              fertilizer, pick the right moment to harvest, and even take the
              wheel of the combine to harvest.
            </p>
            <Button to="gamified-learning">
              Play Now! <Icon name="arrowright" />
            </Button>
          </Column>
          <Column size={6} className="home-harvest-experience__image-column">
            <div className="home-harvest-experience__image-container">
              <div className="square square-top-left"></div>
              <div className="square square-bottom-right"></div>
              <Image
                filename="img-home-harvest.png"
                className="harvest-image"
              />
            </div>
          </Column>
        </Row>
      </Container>
      <Image
        className="home-harvest-experience__repeat-image"
        filename="img-home-wheat-repeat.png"
      />
    </Section>

    <Section className="home__image-text">
      <Container>
        <Row>
          <Column
            large={6}
            style={{ backgroundImage: `url(${colBkgVFT})` }}
            className="pt-6 pl-2 pr-2 pb-3 home__background-img-col"
          >
            <div className="home__text-wrapper">
              <h2>Virtual Field Trip</h2>
              <p className="mb-0">
                <strong>See a Real Agricultural Cooperative in Action</strong>
              </p>
              <p>
                Explore the ways agricultural cooperatives (co-ops) come
                together to achieve a unified goal through individual expertise,
                teamwork, and innovative technologies. Along the way, students
                will meet the awesome personalities working behind-the-scenes
                within a successful co-op to learn the keys to the agricultural
                co-op business model.
              </p>
              <Button to="virtual-field-trip">Watch Now!</Button>
            </div>
          </Column>
          <Column
            large={6}
            style={{ backgroundImage: `url(${colBkgCoop})` }}
            className="pt-6 pl-2 pr-2 pb-3 home__background-img-col"
          >
            {/* <div className="home__coming-soon">
              <span>COMING SOON</span>
            </div> */}
            <div className="home__text-wrapper">
              <h2>Co-op Careers</h2>
              <p className="mb-0">
                <strong>Sow the Seeds of Student Success</strong>
              </p>
              <p>
                Discover the diverse network of talented professionals that come
                together to create agricultural co-ops, from truck drivers to
                nutrition consultants and beyond. Get high school students
                thinking proactively about their future by immersing them in the
                lives of real-world pros who cultivated careers by following
                their own interests and passions.
              </p>
              <p>
                <Button to="cooperative-careers">Learn More</Button>
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="home__cooperative">
      <Container>
        <Row>
          <Column large={5}>
            <div className="element-square"></div>
            <Image
              filename="img-home-sprout-icon.png"
              className="icon-sprout"
            />
          </Column>
          <Column large={7} className="home__cooperative-copy">
            <h2>What’s a Cooperative?</h2>
            <p>
              <strong>Together We Grow</strong>
            </p>
            <p>
              Find out exactly what a <strong>cooperative</strong> is and
              uncover how the <strong>agricultural co-op</strong> business model
              is helping organizations and communities thrive across the country
              and the world.
            </p>
            <p>
              Introduce students to the{' '}
              <strong>7 Cooperative Principles</strong> that guide every
              successful cooperative venture.
            </p>
            <Button to="what-is-a-cooperative">Learn More</Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-2">
      <Container>
        <Row>
          <Column large={7} className="pt-4 pb-1">
            <h2>About the Program</h2>
            <h3>Getting Students into the Business of Cooperatives</h3>
            <p>
              Join the CHS Foundation and Discovery Education as we immerse high
              school students nationwide in the dynamic ingenuity and
              collaborative spirit of the cooperative business model. Empower
              students to cultivate success with no-cost, standards-aligned
              resources that explore essential careers and endless possibilities
              in agricultural cooperatives.
            </p>
            <Button to="about">Learn More</Button>
          </Column>
          <Column large={5} className="home__cooperative-copy">
            <Image filename="img-home-female-student.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
