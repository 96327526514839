import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Badge = ({ text, className, alignTopRight }) => {
  return (
    <span
      className={classNames(
        'badge',
        { 'badge--align-top-right': alignTopRight },
        className
      )}
    >
      {text}
    </span>
  );
};

Badge.propTypes = {
  alignTopRight: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Badge.defaultProps = {
  alignTopRight: false,
  className: null,
};

export default Badge;
